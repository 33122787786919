<template>
<body>
  <div class="menu-container">
    <h1 class="menu-title">Meny</h1>
    <div class="menu-sections">
      <div class="menu-section" v-for="(section, index) in menusections" :key="index">
        <h2 class="section-heading">{{ section.heading }}</h2>
        <ul class="menu-items">
          <li class="menu-item" v-for="(item, itemIndex) in section.items" :key="itemIndex">
            <div class="item-name">
              <span>{{ item.name }}</span>
              <span class="item-price">{{ item.price }}</span>
            </div>
            <p v-if="item.desc" class="item-desc">{{ item.desc }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</body>

</template>

<script>
    export default {
        data() {
            return {
                menusections: [
                    {
                    heading: 'Förrätter',
                    items: [
                    {name: 'Vitlöksbröd med tzatziki', desc: '', price: '79:-'},
                    {name: 'Toast Skagen', desc: 'Klassisk hemmagjord skagenröra på rostat bröd', price: '142:-'},
                    {name: 'Chili och vitlöksfrästa Scampi', price: '142:-'},
                    {name: 'Chicken wings', desc: '6st/12st. Marinerade kycklingvingar med hot tigersås', price: '99:-/189:-'},
                    {name: 'Jalapeño poppers', desc: '6st/12st. Friterade färska ostfyllda chillipeppar, serveras med salsa', price: '99:-/189:-'},
                    {name: 'Mozzarella sticks', desc: '6st/12 st. Friterade mozarella sticks, serveras med salsa', price: '99:-/189:-'},
                    {name: 'Nachos', desc: 'Nacho chips serveras med salsa, guacamole och gräddfil', price: '99:-'},
                    {name: 'Pommes med bea-sås', price: '79:-'},
                    ]
                    },
                    {
                    heading: 'Varmrätter', 
                    items: [
                    {name: 'Schnitzel (fläsk)', desc: 'Serveras med pommes, kapris, citron, rödvinssky & bea-sås', price: '212:-'},
                    {name: 'Hamburgare', desc: 'Angusburgare med dressing, cheddarost, sallad, tomat & rödlök, aioli & pommes', price: '212:-'},
                    {name: 'Halloumiburgare', desc: 'Halloumiburgare med dressing, sallad, tomat & rödlök, aioli & pommes', price: '212:-'},
                    {name: 'Tryffelburgare', desc: 'Tryffelburgare med dressing, cheddarost, sallad, bacon, tomat, rödlök, bea-sås & pommes', price: '222:-'},
                    {name: 'Grillad oxfilé', desc: 'Serveras med pommes, stekta grönsaker, rödvinssky & bea-sås', price: '349:-'},
                    {name: 'Köttbullar', desc: 'Serveras med gräddsås, saltgurka, rårörda lingon & potatismos', price: '212:-'},
                    {name: 'Kycklingfilé', desc: 'Serveras med pommes, stekta grönsaker, rödvinssky & bea-sås', price: '212:-'},
                    {name: 'Filé oscar', desc: 'Grillad fläskfilé. Serveras med pommes, sparris, handskalade räkor, rödvinssky & bea-sås', price: '212:-'}                 
                    ]
                    },
                    {
                    heading: 'Plankstek',
                    items: [
                    {name: 'Klassisk planka', desc: 'Grillad oxfilé. Serveras med duchesse potatis, baconlindad sparris, stekta grönsaker, rödvinssky & bea-sås', price: '349:-'},
                    {name: 'Fläskfilé planka', desc: 'Grillad fläskfilé. Serveras med duchesse potatis, baconlindad sparris, stekta grönsaker, rödvinssky & bea-sås', price: '249:-'}
                    ]
                    }, 
                    {
                    heading: 'Fisk',
                    items: [
                    {name: 'Fish & chips', desc: 'Serveras med pommes & remouladsås', price: '199:-'},
                    ]
                    },
                    {
                    heading: 'Sallader',
                    items: [
                    {name: 'Ceasarsallad (Kyckling)', desc: 'Grillad Kyckling, romansallad, krispig bacon, krutonger, grana padano, cocktailtomater & ceasardessing', price: '202:-'},
                    {name: 'Ceasarsallad (Scampi)', desc: 'Chili & vitlöksfräst Scampi, romansallad, krutonger, grana padano, cocktailtomater & ceasardressing', price: '212:-'},
                    {name: 'Halloumisallad', desc: 'Halloumi, romansallad, krutonger, grana padano, cocktailtomater & aioli', price: '202:-'},
                    {name: 'Räksallad', desc: 'Handskalade räkor, romansallad, krutonger, grana padano, cocktailtomater, citron & aioli', price: '212:-'}
                    ]
                    },
                    {
                    heading: 'Pasta',
                    items: [
                    {name: 'Oxfilépasta', desc: 'För vegetarisk alternativ - välj bort oxfilé. Strimlad oxfilé, tryffel, svartpeppar, lök, chili, vitlök, grana padano, cocktailtomater & grädde', price: '222:-'},
                    {name: 'Pasta Carbonara', desc: 'Bacon, lök, svartpeppar, grädde & grana padano', price: '202:-'},
                    {name: 'Scampi Pasta', desc: 'Scampi, vitt vin, sparris, chili, vitlök, tomat & persilja', price: '222:-'}
                    ]
                    },
                    {
                    heading: 'Barnmeny',
                    items: [
                    {name: 'Fläskfilé', desc: 'Serveras med pommes, rödvinssky & bea-sås', price: '145:-'},
                    {name: '6 st chicken nuggets', desc: 'Serveras med pommes & aioli', price: '145:-'}
                    ]
                    },
                    {
                    heading: 'Dessert',
                    items: [
                    {name: 'Kladdkaka med grädde', price: '89:-'},
                    {name: 'Vaniljglass med jordgubbssås', price: '89:-'}
                    ]
                    }
                ]
            }
        }
    }
</script>

<style scoped>
body {
  font-family: 'Arial', sans-serif;
  background-color: #2b4d2f; /* Grön bakgrund */
  color: #fff;
  margin: 0;
  padding: 0;
}

.menu-container {
  text-align: center;
  padding: 50px 20px;
}

.menu-title {
  font-size: 3.5rem;
  color: #ff8800; /* Orange rubrik */
  margin-bottom: 40px;
  text-transform: uppercase;
}

.menu-sections {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
}

.menu-section {
  width: 90%;
  max-width: 800px;
  background-color: #3a5c3f; /* Mörkare grön för sektionerna */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.section-heading {
  font-size: 2.5rem;
  color: #ff8800;
  border-bottom: 3px solid #ff8800;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.menu-items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-item {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.menu-item:last-child {
  border-bottom: none;
}

.item-name {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
}

.item-price {
  font-weight: bold;
  color: #d4af37; /* Guld-färg för priser */
}

.item-desc {
  margin-top: 5px;
  font-size: 1.2rem;
  color: #dcdcdc;
}

</style>
